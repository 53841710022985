import React from 'react';

export const IconProduct: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <circle fill="#ECA300" cx="50" cy="50" r="50" />
      <g fill="#FFF">
        <path d="M30 67h40c2 0 3-1 3-3V37c0-1-1-3-3-3h-6v3h6v27H31l-1-1V38l1-1h5v-3h-6c-2 0-3 2-3 3v27c0 2 1 3 3 3zM30 75h40c3 0 5-2 5-5H25c0 3 2 5 5 5z" />
        <path d="M42 58h16c2 0 3-2 3-3V28c0-2-1-3-3-3H42c-2 0-3 1-3 3v27c0 1 1 3 3 3zm0-29l1-1h2l2 2h6l2-2h2l1 1v24H42V29z" />
      </g>
    </g>
  </svg>
);
