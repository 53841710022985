import React from 'react';

export const IconPencil: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1700 200">
    <path fill="#f9b233" d="M200 0h1200v200H200z" />
    <path fill="#f39200" d="M200 150h1200v50H200zM200 0h1200v50H200z" />
    <path fill="#dadada" d="M100 0h100v200H100z" />
    <path
      d="M100 0H50C0 0 0 50 0 50v100c0 50 50 50 50 50h50V0z"
      fill="#e94e1b"
    />
    <path fill="#b2b2b2" d="M125 0h50v200h-50z" />
    <path
      d="M1400 200a25 25 0 01-25-25 25 25 0 0125-25 50 50 0 010-100 25 25 0 01-25-25 25 25 0 0125-25l300 100z"
      fill="#ca9e67"
    />
    <path d="M1550 50s-25 0-25 50 25 50 25 50l150-50z" fill="#575756" />
  </svg>
);
