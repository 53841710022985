import React from 'react';

export const IconUx: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#F65" cx="33" cy="33" r="33" />
      <g fill="#FFF" fillRule="nonzero">
        <path d="M52 48c.3-.4.5-1 .5-1.5V37c0-1.3-1.1-2.4-2.4-2.4h-.5c-.3-1-1.2-1.7-2.3-1.7h-1.1c-.5-.5-1.2-.9-2-.9h-.4-.2v-2.3c0-1.5-1.2-2.6-2.6-2.6a2.6 2.6 0 00-2.6 2.6v9l-.1.2H38l-.4-.3c-1.1-.7-2.4-1.2-3.7-1.4a2.3 2.3 0 00-1.6 4.2l1.4 1c1.6 1.2 3 3.1 4 5.3v.2l.5.7c-.3.4-.4.9-.4 1.4v.6c0 1.3 1 2.4 2.3 2.4h6.3a1 1 0 000-2.2h-6.3a.2.2 0 01-.2-.2V50l.2-.2h10.4c.1 0 .2 0 .2.2v.6c0 .1 0 .2-.2.2h-.8a1 1 0 000 2.2h.8c1.3 0 2.4-1 2.4-2.4V50c0-.8-.4-1.5-1-2zm-11.2-.4a1 1 0 01-1-.6v-.2c-1.1-2.5-2.8-4.7-4.8-6.1l-1.4-1v-.2-.1h.1c1 0 2 .4 2.7 1l.5.2a2.4 2.4 0 003.7-2v-9-.3h.4c.2 0 .4.1.4.4v5.7a1 1 0 002.2 0v-1c0-.1 0-.2.2-.2h.4c.2 0 .3 0 .3.2v1.9a1 1 0 002.1 0v-1l.2-.2h.5s.2 0 .2.2v2.3a1 1 0 002.1 0V37c0-.1.1-.2.3-.2h.2c.1 0 .2 0 .2.2v9.5c0 .6-.5 1-1 1h-8.5z" />
        <path d="M34 27.3v-4.8a3 3 0 016 0v2.4a1 1 0 002.2 0v-2.4a5.1 5.1 0 00-10.3 0v4.8a6.8 6.8 0 01-13.5 0V16.7l1.4 1.4a1 1 0 001.5 0 1 1 0 000-1.5l-3.2-3.3-.2-.1-.2-.1h-.2c-.1-.1-.2-.1-.4 0h-.4l-.1.2-3.3 3.3a1 1 0 101.5 1.5l1.4-1.4v10.6a9 9 0 0017.9 0z" />
      </g>
    </g>
  </svg>
);
