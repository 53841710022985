import React from 'react';

export const IconFigma: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 300">
    <path
      id="path0_fill"
      fill="#0acf83"
      d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z"
    />
    <path
      id="path1_fill"
      fill="#a259ff"
      d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z"
    />
    <path
      id="path1_fill_1_"
      fill="#f24e1e"
      d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z"
    />
    <path
      id="path2_fill"
      fill="#ff7262"
      d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z"
    />
    <path
      id="path3_fill"
      fill="#1abcfe"
      d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
    />
  </svg>
);

export default IconFigma;
