import React from 'react';

export const IconGit: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219 92">
    <g fill="none" fillRule="evenodd">
      <path
        d="M218.4 65.2a34.8 34.8 0 01-14.2 3.5c-10.4 0-14.3-4.2-14.3-14V31.9c0-.5 0-.9-.7-.9h-6v-7.7c7.6-1 10.6-4.7 11.6-14.2h8.3v12.3c0 .6 0 1 .7 1H216V31h-13v20.8c0 5.1 1.3 7.1 6 7.1 2.4 0 5-.6 7-1.4l2.4 7.7"
        fill="#fff"
      />
      <path
        d="M89.4 42.4L49.6 2.6a5.9 5.9 0 00-8.3 0l-8.2 8.2 10.4 10.5a7 7 0 018.9 9l10 10a7 7 0 11-4.1 4l-9.4-9.5v24.8a7 7 0 11-5.7-.2v-25c-.9-.3-1.7-.8-2.4-1.5a7 7 0 01-1.5-7.7L29 15 1.7 42.2a5.9 5.9 0 000 8.3l39.8 39.8c2.3 2.3 6 2.3 8.3 0l39.6-39.6c2.3-2.3 2.3-6 0-8.3"
        fill="#DE4C36"
      />
      <path
        d="M130.9 31.8c-4.8 0-8.4 2.4-8.4 8 0 4.3 2.4 7.3 8.1 7.3 4.9 0 8.2-2.9 8.2-7.4 0-5.2-3-7.9-8-7.9zm-9.6 40c-1.1 1.4-2.3 2.9-2.3 4.6 0 3.5 4.5 4.5 10.6 4.5 5 0 11.9-.3 11.9-5 0-2.8-3.3-3-7.5-3.2l-12.7-1zM147.1 32c1.5 2 3.2 4.8 3.2 8.8 0 9.6-7.6 15.3-18.6 15.3-2.7 0-5.3-.3-6.8-.8L122 60l8.5.6c15 1 24 1.4 24 13 0 10-8.8 15.6-24 15.6-15.7 0-21.7-4-21.7-10.9 0-4 1.7-6 4.8-8.9a5.8 5.8 0 01-3.9-5.7c0-2 1-3.7 2.5-5.3 1.6-1.7 3.4-3.3 5.4-5.2-4.2-2.1-7.4-6.7-7.4-13.1 0-10 6.6-16.9 19.9-16.9 3.7 0 6 .4 8 .9h17v7.4l-8 .6zM170.4 16.3c-5 0-7.9-2.9-7.9-7.9s3-7.6 7.9-7.6c5 0 7.9 2.7 7.9 7.6 0 5-2.9 7.9-8 7.9zm-11.2 52.3v-6.9l4.4-.6c1.2-.2 1.4-.4 1.4-1.7V33.8c0-1-.3-1.6-1.2-1.9l-4.6-1.6 1-7h18v36c0 1.5 0 1.6 1.3 1.8l4.5.6v6.9h-24.8z"
        fill="#fff"
      />
    </g>
  </svg>
);
