import React from 'react';

export const IconDirection: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg">
    <circle cx="31.5" cy="31.5" r="31.5" fill="#609834" />
    <path
      d="M31.6379 11C20.2545 11 11 20.2545 11 31.6379c0 11.3835 9.2545 20.638 20.6379 20.638 11.3835 0 20.638-9.2545 20.638-20.638C52.2759 20.2545 43.0214 11 31.6379 11zm1.1731 38.8428v-3.7366c0-.6517-.5213-1.1731-1.1731-1.1731-.6517 0-1.1731.5214-1.1731 1.1731v3.7366c-9.1241-.6083-16.4234-7.9076-17.0317-17.0318h3.7366c.6517 0 1.1731-.5213 1.1731-1.1731 0-.6517-.5214-1.1731-1.1731-1.1731h-3.7366c.6083-9.1241 7.9076-16.4234 17.0317-17.0317v3.7366c0 .6517.5214 1.1731 1.1731 1.1731.6518 0 1.1731-.5214 1.1731-1.1731v-3.7366c9.1242.6083 16.4235 7.9076 17.0318 17.0317h-3.7366c-.6517 0-1.1731.5214-1.1731 1.1731 0 .6518.5214 1.1731 1.1731 1.1731h3.7366c-.6083 9.1242-7.9076 16.4235-17.0318 17.0318z"
      fill="#fff"
    />
    <path
      d="M39.3282 20.9062l-11.4703 5.04c-.869.391-1.5641 1.0428-1.9117 1.9117l-5.04 11.4704c-.3911.8689-.1738 1.8682.4779 2.52.4345.4345.9993.6517 1.6076.6517.3041 0 .6083-.0435.9124-.1738l11.4703-4.9966c.869-.391 1.5642-1.0427 1.9118-1.9117l4.9965-11.4703c.3911-.869.1738-1.8683-.4779-2.52s-1.6076-.869-2.4766-.5214zm-4.171 13.5559c-.1303.3041-.391.5648-.6952.6951l-11.2531 4.9097 4.9097-11.2531c.1303-.3042.391-.5648.6952-.6952l11.2531-4.9096-4.9097 11.2531z"
      fill="#fff"
    />
    <path
      d="M33.327 33.3296c.9333-.9331.9335-2.4461.0005-3.3795-.9331-.9333-2.4461-.9335-3.3795-.0004-.9333.933-.9335 2.4461-.0005 3.3794.9331.9334 2.4462.9336 3.3795.0005z"
      fill="#fff"
    />
  </svg>
);
