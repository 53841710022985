import React, { SVGAttributes } from 'react';

export const IconGitHub: React.FC<SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M32 .8C14.3.8 0 15.1 0 32.8c0 14.1 9.2 26.1 21.9 30.4 1.6.3 2.2-.7 2.2-1.5v-5.4C15.2 58.2 13.3 52 13.3 52c-1.5-3.7-3.6-4.7-3.6-4.7-2.9-2 .2-1.9.2-1.9 3.2.2 4.9 3.3 4.9 3.3 2.9 4.9 7.5 3.5 9.3 2.7.3-2.1 1.1-3.5 2-4.3-7.1-.8-14.6-3.6-14.6-15.8 0-3.5 1.2-6.3 3.3-8.6-.3-.8-1.4-4.1.3-8.5 0 0 2.7-.9 8.8 3.3 2.6-.7 5.3-1.1 8-1.1s5.5.4 8 1.1c6.1-4.1 8.8-3.3 8.8-3.3 1.7 4.4.6 7.7.3 8.5 2.1 2.2 3.3 5.1 3.3 8.6 0 12.3-7.5 15-14.6 15.8 1.1 1 2.2 2.9 2.2 5.9v8.8c0 .9.6 1.9 2.2 1.5C54.8 58.9 64 46.9 64 32.8c0-17.7-14.3-32-32-32z"
      fill="#666"
      fillRule="evenodd"
    />
  </svg>
);
