import React from 'react';

export const IntroShoes: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 600">
    <g fill="none" fillRule="evenodd">
      <path fill="#788FAB" d="M0 0h1200v600H0z" />
      <path
        d="M603 197.4l-56 56h127a171 171 0 0012.6-3.5 207.9 207.9 0 015.1-1.3v-76l-66.2-12.4h-11.2c-1.5 0-3 .3-4.3.8a11.3 11.3 0 00-6 5.9 11 11 0 00-1 4.4v26.1"
        fill="#D48C00"
      />
      <path
        d="M510.7 275.6h181.8v-33.4H528.3a36.6 36.6 0 00-23.6 8.4 38.5 38.5 0 00-13 21.4 109.5 109.5 0 009.9 2 357 357 0 009 1.6"
        fill="#EBA300"
      />
      <path
        d="M700 279.5l-30.6-4.2h-136a40.2 40.2 0 00-7.2.5 113.1 113.1 0 00-7 1l-28.2 2.7v22.3h112l7.5-14.8h29.8v14.8H700v-22.3"
        fill="#613D30"
      />
      <path
        d="M662.7 272l21.6 4.7L700 272v-82l-14.9-8.1v45.4a51.2 51.2 0 01-6.2 24.8 60.8 60.8 0 01-16.2 20"
        fill="#8E562E"
      />
      <path d="M491.8 272a37 37 0 00-.8 7.5h209V272H491.8" fill="#FFC83D" />
      <path
        d="M587 228.4l-7.5-7.5c-.7-.7-1.5-1-2.6-1-1 0-2 .3-2.6 1-.8.8-1.1 1.6-1.1 2.7 0 1 .3 1.9 1 2.6l7.5 7.4 5.3-5.2zm14.8-15l-7.3-7.5c-.7-.7-1.6-1-2.7-1-1 0-2 .4-2.6 1a4 4 0 00-1 2.7c0 1 .3 2 1 2.6l7.4 7.5 5.2-5.3zm12.4-16H603c-1 0-2 .4-2.7 1.1-.7.7-1 1.6-1 2.7 0 1 .3 1.9 1 2.6.8.7 1.6 1.1 2.7 1.1h11.2v-7.5zm0-15H603c-1 0-2 .4-2.7 1.2-.7.7-1 1.6-1 2.6s.3 2 1 2.7c.8.7 1.6 1 2.7 1h11.2v-7.4zm0-14.8H603c-1 0-1.9.4-2.6 1.1a3.5 3.5 0 00-.1 5.2c.8.7 1.6 1.1 2.7 1.1h11.2v-7.4z"
        fill="#FFB900"
      />
      <path
        d="M580.5 231c0 1 .4 1.9 1.1 2.6.8.7 1.6 1 2.7 1 1 0 2-.3 2.6-1 .8-.7 1.1-1.6 1.1-2.7 0-1-.4-1.8-1.1-2.5a3.7 3.7 0 00-5.2 0c-.8.7-1.2 1.6-1.2 2.5zm15-14.9c0 1 .4 2 1 2.7a4 4 0 002.7 1c1 0 2-.3 2.7-1a4 4 0 001-2.7c0-1-.3-2-1-2.6a3.3 3.3 0 00-2.7-1.1c-1 0-1.9.3-2.6 1a4 4 0 00-1.1 2.7zm15-15c0 1.1.3 2 1 2.7a3.9 3.9 0 005.3.1c.8-.8 1.1-1.7 1.1-2.7 0-1-.3-2-1-2.7a4 4 0 00-2.7-1c-1 0-2 .3-2.7 1-.7.7-1 1.6-1 2.7zm0-14.9c0 1 .3 2 1 2.7.8.7 1.6 1 2.7 1 1 0 2-.3 2.6-1 .8-.8 1.1-1.6 1.1-2.7 0-1-.3-1.9-1-2.6a3.6 3.6 0 00-2.7-1.1c-1 0-2 .3-2.7 1a4 4 0 00-1 2.7zm0-15c0 1.1.3 2 1 2.7a3.9 3.9 0 005.3.1c.8-.8 1.1-1.7 1.1-2.7 0-1-.4-1.8-1.1-2.6a3.7 3.7 0 00-5.2 0c-.8.8-1.1 1.6-1.1 2.6zm22.3 18.8H700v-22.4c0-2-.8-3.8-2.2-5.3a7.2 7.2 0 00-5.3-2.1h-67.1v22.3a7 7 0 002.2 5.2 7 7 0 005.2 2.3z"
        fill="#383838"
      />
      <path
        d="M173 294.3c0 4.8 3.9 8.7 8.7 8.7h190.6a8.7 8.7 0 100-17.3H181.7a8.7 8.7 0 00-8.7 8.6"
        fill="#CCD6DD"
      />
      <path
        d="M372.8 229.3s3 .4 6 12.6c3 12.1 2 23.6 2 38.7 0 3.9-6 6.2-6 6.2l-193.7.3s-6-2.6-6-6.3c0-7.9 3.8-30.4 29.2-30.4 6.1 0 30.5.3 49.4-13.6 7-5.1 33.8-16.6 46-22.9 12-6.3 12.6 21.8 36.8 21.8 24.2 0 24.2-6.3 36.3-6.4"
        fill="#55ACEE"
      />
      <path
        d="M309.7 250.8l3.6-10.5a37.2 37.2 0 01-21.1-16.2l-9.8 4.9c.3.6 7.3 14.8 27.3 21.8m-27.3 13.4l3.6-10.5a37.8 37.8 0 01-21.1-16.2l-9.8 4.9c.3.6 7.3 14.8 27.3 21.8"
        fill="#269"
      />
      <path
        d="M178.8 292h196.4a6 6 0 000-11.8H178.8a5.8 5.8 0 00-5.8 5.8 6 6 0 005.8 6"
        fill="#3B88C3"
      />
      <g>
        <path
          d="M1018.6 487v-81c0-7.1-1.5-13.8-4.6-20-3.1-6.3-.6-16.2-6.3-20.5V190.6l-25.2-2.5c-1.2 1.1-2.5 2-4 2.5s-3 .7-4.7.7c-3.6 0-6.8-1-9.5-3.2h-81.6l18.1 190.5v9a69 69 0 01-5.7 28 83 83 0 01-29.4 29.2 70 70 0 01-28.1 5.7h-9a18.8 18.8 0 00-16.7 11 92 92 0 00-1.5 16.5l9.7 9 90 .2 108.5-.1z"
          fill="#CA5010"
        />
        <path fill="#613D30" d="M810.4 478v18h208.2v-25h-27l-81.6 7z" />
        <path
          d="M964.6 278.2a9 9 0 009.1 8.9c2.4 0 4.6-.9 6.4-2.6a8.5 8.5 0 002.7-6.3V188h-18.2v90.2"
          fill="#F7894A"
        />
      </g>
      <g>
        <path
          d="M550.7 429.2h-22.4a36 36 0 00-26.3 11 38.6 38.6 0 00-11 26.4c22.5 3 44.8 5.2 67 6.9a903 903 0 0067.3 2.5c6.6 0 13.2-.5 19.8-1.5a98 98 0 0019-4.8 74.3 74.3 0 0017.1-9.2 56.6 56.6 0 0018.8-23.8V392a10.2 10.2 0 00-7.3-3c-1.4 0-2.8.3-4.1.8a9.9 9.9 0 00-3.5 2.2l-22.4 22.4h-7.5c-3.2 0-6-.3-8.1-.8-2.2-.6-4.1-1.5-5.7-2.7-1.6-1.2-3-2.7-4.4-4.6a83.4 83.4 0 01-4.2-6.8l-.6-1.2-4-6.4a58.5 58.5 0 00-4.9-7 30 30 0 00-5.8-5.5 11.1 11.1 0 00-6.6-2.3h-8l-52.2 52.1"
          fill="#41DABC"
        />
        <path
          d="M573 466.6v7.4h112v-37.3h-59.7a59.4 59.4 0 00-30 8 62 62 0 00-22.2 21.9"
          fill="#00B294"
        />
        <path
          d="M677.6 436.7a57.8 57.8 0 01-22.1 21.8 61.2 61.2 0 01-30.2 8H491v22.3h209v-52.1h-22.4"
          fill="#E5E5E5"
        />
        <path
          d="M685 392h15v-22.4c0-2-.8-3.7-2.3-5.2a7.2 7.2 0 00-10.4 0 7.2 7.2 0 00-2.2 5.2L685 392"
          fill="#CCC"
        />
        <path
          d="M570.9 409.1a7 7 0 00-5.2-2.2c-2 0-3.8.8-5.3 2.2a7.2 7.2 0 00-2.2 5.3 7 7 0 002.2 5.2l14.9 15a7.2 7.2 0 005.2 2.1 7 7 0 005.3-2.2 7.2 7.2 0 002.2-5.3c0-2-.7-3.8-2.2-5.3l-15-14.8zm22.4-22.4a7.2 7.2 0 00-5.3-2.2c-2 0-3.8.8-5.3 2.2a7.2 7.2 0 00-2.2 5.3c0 2 .8 3.8 2.2 5.3l15 14.8a7.3 7.3 0 0010.5 0 7.2 7.2 0 002.3-5.2c0-2-.8-3.8-2.2-5.2l-15-15z"
          fill="#FFF"
        />
      </g>
      <g>
        <path
          d="M306.8 392.4a8.6 8.6 0 00-1.8 5.2c0 1.7.5 3.3 1.3 4.8s1.9 2.9 3.1 4c1.3 1.2 2.8 2 4.4 2.8a12 12 0 004.8 1.1h46.8l.8-17.9h-59.4"
          fill="#F7894A"
        />
        <path
          d="M232.5 422.3h-22.3a37.4 37.4 0 00-37.1 37.4l5.5 5.3H376l5-5.3v-52.3a14.8 14.8 0 00-14.8-15l-7.4 15h-37.2c-2 0-4-.4-5.8-1.2a15.4 15.4 0 01-7.9-8c-.7-1.7-1.1-3.7-1.1-5.8a21.7 21.7 0 00-6.6-15.8 21.4 21.4 0 00-15.8-6.6l-51.9 52.3"
          fill="#CA5010"
        />
        <path
          d="M256.9 427.6a7.2 7.2 0 005.3 2.1c2 0 3.8-.7 5.2-2.1a7.2 7.2 0 002.2-5.3 7 7 0 00-2.2-5.3l-14.8-15a7 7 0 00-5.2-2.2c-2 0-3.8.7-5.3 2.2a7.2 7.2 0 00-2.2 5.3c0 2 .8 3.7 2.2 5.2l14.8 15zm22.3-22.5a7.2 7.2 0 0010.5 0 7.2 7.2 0 002.2-5.2c0-2-.7-3.8-2.1-5.3l-15-15a7.2 7.2 0 00-5.2-2.1c-2 0-3.8.7-5.3 2.2a7.2 7.2 0 00-2.1 5.3c0 2 .7 3.8 2.1 5.3l15 14.8zM381 459.7H173V482h111.5l14.9-14.9h22.2l14.9 14.9H381v-22.3z"
          fill="#613D30"
        />
      </g>
    </g>
  </svg>
);
