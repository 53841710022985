import React, { SVGAttributes } from 'react';

export const IconPhone: React.FC<SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
    <path
      d="M45 0C20.3 0 .2 20.1 0 44.7c-.1 12 4.5 23.4 13 31.9 8.4 8.6 19.7 13.3 31.7 13.4h.3c24.7 0 44.8-20.1 45-44.7C90.2 20.5 70.1.2 45 0zm21.8 60l-1.9 1.9-.1.1c-3.3 3.3-6.1 4.1-10.5 2.8-4.6-1.4-8.7-3.9-12.4-6.9-5.3-4.3-11-9.9-14.7-15.7-3.7-6-4.9-12.3.3-17.5l.1-.1 1.9-1.9c1.4-1.4 3.7-1.4 5.2 0l5.9 5.9c1.4 1.4 1.4 3.7 0 5.2l-3.5 3.5.1.1c3.9 5.9 9 11 14.9 14.9l.1.1 3.5-3.5c1.4-1.4 3.7-1.4 5.2 0l5.9 5.9c1.5 1.5 1.5 3.8 0 5.2z"
      fill="#666"
      fillRule="nonzero"
    />
  </svg>
);
