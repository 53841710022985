import React from 'react';

export const IntroEducation: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
    <defs>
      <clipPath id="eduPath">
        <path
          d="M21.8 52.4l4.6-9.2a24 24 0 1140.5 17.4 14.4 14.4 0 00-4.5 10.5v5.7h-24v-7.2a2.4 2.4 0 00-2.4-2.4h-3.8a5.8 5.8 0 01-5.8-5.8v-6.2h-2.9a1.8 1.8 0 01-1.7-2.8z"
          fill="none"
        />
      </clipPath>
    </defs>
    <path fill="#2D3741" d="M0 0h96v96H0z" />
    <path
      d="M21.8 52.4l4.6-9.2a24 24 0 1140.5 17.4 14.4 14.4 0 00-4.5 10.5v5.7h-24v-7.2a2.4 2.4 0 00-2.4-2.4h-3.8a5.8 5.8 0 01-5.8-5.8v-6.2h-2.9a1.8 1.8 0 01-1.7-2.8z"
      fill="#fff"
    />
    <path
      d="M40.9 45a1.4 1.4 0 00.9-.2 1.5 1.5 0 00.4-1.3 2.4 2.4 0 00-.2-1 .8.8 0 00-.8-.5c-.5.1-.7.6-1.1.7a1 1 0 01-1.1-.9 15.7 15.7 0 00-.2-1.6 2.2 2.2 0 00-2.8-1 4 4 0 00-2.2 2.4 1.3 1.3 0 01-1 1.1l-.7-.3a1.6 1.6 0 00-1.9 1.7c.1 1 1.7.8 2.4.8h8.3zM69.2 48.6h.5a.9.9 0 00.2-.8 1.4 1.4 0 00-.1-.6.5.5 0 00-.5-.3c-.3 0-.4.4-.7.4a.6.6 0 01-.7-.6 9.4 9.4 0 00-.1-1 1.3 1.3 0 00-1.7-.7 2.4 2.4 0 00-1.3 1.5.8.8 0 01-.6.7l-.3-.2a1 1 0 00-1.1 1c.1.6 1 .5 1.5.5h5z"
      fill="#dde1e4"
    />
    <g clipPath="url(#eduPath)">
      <path
        d="M65.4 58.8H30.6a.6.6 0 00-.6.6v3h36v-3a.6.6 0 00-.6-.6z"
        fill="#dde1e4"
      />
      <path
        d="M58.8 55.2H37.2a.6.6 0 00-.6.6v3h22.8v-3a.6.6 0 00-.6-.6z"
        fill="#dde1e4"
      />
      <path
        d="M61.8 51.6H48.6a.6.6 0 00-.6.6v6.6h14.4v-6.6a.6.6 0 00-.6-.6z"
        fill="#dde1e4"
      />
      <path fill="#71869e" d="M19.2 62.4h57.6V96H19.2z" />
      <path fill="#cf4882" d="M40.2 0h19.2v62.4H40.2z" />
      <path
        d="M40.2 60h19.2a.6.6 0 01.6.6v1.8H39.6v-1.8a.6.6 0 01.6-.6z"
        fill="#c2603c"
      />
      <path
        d="M45.6 43.2h8.3a1.2 1.2 0 011.2 1.2v18H44.4v-18a1.2 1.2 0 011.2-1.2z"
        fill="#91482d"
      />
      <path fill="#61301e" d="M44.4 60h10.8v2.4H44.4z" />
      <path
        fill="none"
        stroke="#30180f"
        strokeMiterlimit="10"
        strokeWidth=".6"
        d="M49.7 62.4V43.2"
      />
      <path
        fill="#fff"
        d="M49.8 28.8l3.2 6.4h-6.4l3.2-6.4zM48.2 35.2h3.2v3.2h-3.2z"
      />
    </g>
    <circle cx="63.2" cy="34.2" r="1.8" fill="#dde1e4" />
    <path fill="#516781" d="M58.8 76.8h-18l6-14.4h6l6 14.4z" />
  </svg>
);
