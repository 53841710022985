import React, { SVGAttributes } from 'react';

export const IconAdvertising: React.FC<SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g fillRule="nonzero" fill="none">
      <circle fill="#56ACEF" cx="50" cy="50" r="50" />
      <path
        d="M25.6 20h38c.9 0 1.6.7 1.6 1.6v19.6L62 44V23.2H27.2v35.5h9c.2 1.1.6 2.2 1.2 3.2H25.6c-.9 0-1.6-.7-1.6-1.6V21.6c0-.9.7-1.6 1.6-1.6zm25 10.3a2.6 2.6 0 110 5.3 2.6 2.6 0 010-5.3zm-19.2 12c-2.2 0-2.2-3.2 0-3.2h7.5c2.1 0 2.1 3.2 0 3.2h-7.5zm0 6.2c-2.2 0-2.2-3.2 0-3.2h7.5c2.1 0 2.1 3.2 0 3.2h-7.5zm0-12.5c-2.2 0-2.2-3.2 0-3.2h7.5c2.1 0 2.1 3.2 0 3.2h-7.5zm0-6.2c-2.2 0-2.2-3.3 0-3.3h7.5c2.1 0 2.1 3.3 0 3.3h-7.5zm14.1 8h10.3v-9.7H45.5v9.7zM57.4 41H43.9c-.9 0-1.6-.7-1.6-1.6v-13c0-.8.7-1.6 1.6-1.6h13.5c.9 0 1.6.8 1.6 1.6v13c0 1-.7 1.6-1.6 1.6zm-.7 11.4h-10V62h10v-9.6zm1-3.2L70 38.3c1-1 2.6-.2 2.6 1.2v11.4a7 7 0 010 12.9v11.4c0 1.4-1.7 2.1-2.7 1.2L57.4 65.3h-2.6v10.5a5.2 5.2 0 01-10.3 0V65.2a8 8 0 01-7.4-7.8 8 8 0 018-8.2h12.6zm15 5.5V60c.7-.8 1-1.6 1-2.6 0-1.1-.3-2-1-2.7zm-3.3-11.6L60 51.6V63l9.5 8.5V43.1zM47.7 69.9h3.9v-4.6h-3.9v4.6zm3.9 3.2h-3.9v2.7a2 2 0 103.9 0v-2.7zm-8.2-20.4c-1.9.7-3 2.5-3 4.7 0 2 1.2 3.7 3 4.3v-9z"
        fill="#FFF"
      />
    </g>
  </svg>
);
