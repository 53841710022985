import React from 'react';

export const IconDesignThinking: React.FC<React.SVGAttributes<SVGElement>> =
  () => (
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#00A2D9" cx="33" cy="33" r="33" />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M37.97 24.94c.53 0 .97-.44.97-.97v-9.68a.97.97 0 00-.97-.97h-9.68a.97.97 0 00-.97.97v9.68c0 .53.44.97.97.97h9.68zm-8.71-9.68H37V23h-7.74v-7.74zM17.56 51.71c0 .53.44.97.97.97h4.68c.53 0 .97-.44.97-.97v-4.68a.97.97 0 00-.97-.97h-4.68a.97.97 0 00-.97.97v4.68zM19.5 48h2.74v2.74H19.5V48zM30.79 46.06a.97.97 0 00-.97.97v4.68c0 .53.44.97.97.97h4.68c.53 0 .97-.44.97-.97v-4.68a.97.97 0 00-.97-.97h-4.68zm3.71 4.68h-2.74V48h2.74v2.74zM43.05 46.06a.97.97 0 00-.97.97v4.68c0 .53.43.97.97.97h4.68c.53 0 .96-.44.96-.97v-4.68a.97.97 0 00-.96-.97h-4.68zm3.7 4.68h-2.73V48h2.74v2.74zM20.87 27.76a3.31 3.31 0 100 6.62 3.31 3.31 0 000-6.62zm0 4.68a1.37 1.37 0 110-2.75 1.37 1.37 0 010 2.75zM45.39 27.76a3.31 3.31 0 100 6.62 3.31 3.31 0 000-6.62zm0 4.68a1.37 1.37 0 110-2.75 1.37 1.37 0 010 2.75z" />
          <path d="M20.87 37.6a.97.97 0 00-.97.96v3.82a.96.96 0 00-.98 1.65l1.4.97a.94.94 0 00.3.14c.08.02.16.04.25.04a.95.95 0 00.31-.06.95.95 0 00.24-.11l1.4-.98a.97.97 0 00-.98-1.65v-2.85h10.32v2.85a.96.96 0 00-.98 1.65l1.4.97a.96.96 0 001.1 0l1.4-.97a.97.97 0 00-.98-1.65v-2.85h10.32v2.85a.96.96 0 00-.98 1.65l1.4.97a.94.94 0 00.29.14c.08.02.17.04.26.04a.95.95 0 00.3-.06.95.95 0 00.24-.11l1.4-.98a.97.97 0 00-.98-1.65v-3.82a.97.97 0 00-.97-.96H34.1v-5.57h6.37a.97.97 0 100-1.93H34.1v-3.55a.97.97 0 10-1.94 0v3.55H25.8a.97.97 0 100 1.93h6.37v5.57H20.87z" />
        </g>
      </g>
    </svg>
  );
