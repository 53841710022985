import React from 'react';

export const IconAfterEffects: React.FC<React.SVGAttributes<SVGElement>> =
  () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 234">
      <path d="M10 10h220v214H10V10z" fill="#1f0040" />
      <path
        d="M0 0v234h240V0H0zm10 10h220v214H10V10zm57.8 122.5l-7.9 29.9c-.2.8-.5 1.1-1.5 1.1H43.7c-1 0-1.2-.3-1-1.5l28.4-99.3c.5-1.8.8-3.2 1-8.2 0-.7.3-1 .8-1h21c.7 0 1 .2 1.2 1l31.8 107.7c.2.8 0 1.3-.8 1.3h-16.5c-.8 0-1.3-.3-1.5-1l-8.3-30h-32zm28.1-16.2c-2.8-11.1-9.6-35.3-12.1-47h-.2c-2.1 11.7-7.6 31.5-11.7 47h24zm53.6 10.2c.2 13.5 6.6 22.6 21.8 22.6 5.9 0 11-.8 16.3-3.1.7-.3 1.2-.2 1.2.7v12.5c0 1-.3 1.5-1 2a45.3 45.3 0 01-20.1 3.8c-26.4 0-36.3-19.5-36.3-41.3 0-23.6 12.2-42.9 33.7-42.9 21.8 0 29.4 18.3 29.4 33.2 0 4.8-.3 8.7-.8 10.6-.2.8-.5 1.1-1.3 1.3-2 .3-7.9.7-16.7.7l-26.2-.1zm20.3-13.8c5.1 0 6.9 0 7.4-.2l.2-1.7c0-5.4-2.6-15.4-13-15.4-9.6 0-13.7 8.4-14.7 17.3h20.1z"
        fill="#d291ff"
      />
    </svg>
  );
