import React from 'react';

export const IconGatsby: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg viewBox="0 0 107 28" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <g fill="#FFF">
        <path d="M62.9 12h2.8v10h-2.8v-1.3a3.4 3.4 0 01-3.1 1.6c-3.1 0-5.1-2.4-5.1-5.3 0-3 2-5.3 4.9-5.3.8 0 2.3.1 3.2 1.6V12h.1zm-5.2 5c0 1.6 1.1 2.8 2.8 2.8 1.6 0 2.8-1.2 2.8-2.8 0-1.6-1.1-2.8-2.8-2.8a2.7 2.7 0 00-2.8 2.8zM71.2 14.4V22h-2.8v-7.6h-1.1V12h1.1V8.6h2.8V12h1.9v2.4zM79.7 14.4c-.7-.6-1.3-.7-1.6-.7-.7 0-1.1.3-1.1.8 0 .3.1.6.9.9l.7.2c.8.3 2 .6 2.5 1.4.3.4.5 1 .5 1.7 0 .9-.3 1.8-1.1 2.5s-1.8 1.1-3 1.1c-2.1 0-3.2-1-3.9-1.7l1.5-1.7c.6.6 1.4 1.2 2.2 1.2.8 0 1.4-.4 1.4-1.1 0-.6-.5-.9-.9-1l-.6-.2c-.7-.3-1.5-.6-2.1-1.2-.5-.5-.8-1.1-.8-1.9 0-1 .5-1.8 1-2.3.8-.6 1.8-.7 2.6-.7a5 5 0 013.2 1.1l-1.4 1.6zM85.8 13.3c1-1.4 2.4-1.6 3.2-1.6 2.9 0 4.9 2.3 4.9 5.3s-2 5.3-5 5.3c-.6 0-2.1-.1-3.2-1.6V22H83V5.2h2.8v8.1zm-.3 3.7c0 1.6 1.1 2.8 2.8 2.8 1.6 0 2.8-1.2 2.8-2.8 0-1.6-1.1-2.8-2.8-2.8a2.7 2.7 0 00-2.8 2.8zM98.5 20.5L93.7 12H97l3.1 5.7 2.8-5.7h3.2l-8 15.3h-3.2zM54 13.7h-2.8H47v2.8h3.7c-.6 1.9-2 3.2-4.6 3.2-2.9 0-5-2.4-5-5.3S43.1 9 46 9c1.6 0 3.2.8 4.2 2.1l2.3-1.5A8 8 0 0038 14.4a8 8 0 008 8.1 8 8 0 008-8.1v-.7z" />
      </g>
      <g fill="#FFF">
        <path d="M25 14h-7v2h4.8a9 9 0 01-5.8 6.5L5.5 11A9 9 0 0114 5c3 0 5.7 1.5 7.4 3.8l1.5-1.3a11 11 0 00-19.6 4.1l13.2 13.2C21.3 23.6 25 19.2 25 14zM3 14.1a10.9 10.9 0 0010.8 10.8L3 14.1z" />
      </g>
      <path
        d="M14 0A14 14 0 000 14a14 14 0 0014 14 14 14 0 0014-14A14 14 0 0014 0zM6.2 21.8A10.9 10.9 0 013 14.2L13.9 25c-2.8-.1-5.6-1.1-7.7-3.2zm10.2 2.9L3.3 11.6a11 11 0 0119.6-4.1l-1.5 1.3A9.2 9.2 0 0014 5a9.1 9.1 0 00-8.5 6L17 22.5a9 9 0 005.8-6.5H18v-2h7a11 11 0 01-8.6 10.7z"
        fill="#639"
      />
    </g>
  </svg>
);
