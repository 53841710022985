import React from 'react';

export const IconInDesign: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 234">
    <path fill="#2e0013" d="M10.1 10.1h220V224h-220z" />
    <path
      d="M239.9 0v234H0V0h239.9zM10.1 10.1V224h220V10.1h-220zm77.6 152.1V54.6c0-.5-.1-.8-.2-.9a2 2 0 00-.9-.2H71.1c-.5 0-.8.1-1 .3-.2.2-.3.6-.3 1v107.4c0 .3.1.6.3.8.2.2.6.3 1 .3h15.3c.6 0 1-.1 1.1-.2.2-.1.2-.4.2-.9zm17.6-38.8c0 14 3.6 24.4 10.7 31.3a37.6 37.6 0 0027.2 10.3c4.4 0 8.8-.5 13.3-1.4a49.3 49.3 0 0013.7-5.1l.2-.7c-.3-1.8-.5-3.6-.5-5.4v-98l-.2-.7-.7-.2h-16.5l-.6.2a1 1 0 00-.3.7v26.7l-2.5-.2h-2.7a38.3 38.3 0 00-30 12.3 43.7 43.7 0 00-11.1 30.2zm46.4-26.3v50.7a8 8 0 01-3.3.9c-1.3.2-2.6.2-3.8.2-5.8 0-10.7-2.1-14.7-6.2s-6.1-10.8-6.1-20.1c0-8.4 2.1-14.9 6.2-19.5s9.4-7 15.8-7l3.3.2 2.6.8z"
      fill="#ff3f94"
    />
  </svg>
);
