import React from 'react';

export const IconBranding: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <circle fill="#00B294" cx="50" cy="50" r="50" />
      <g fill="#FFF">
        <path d="M38.4 30.7h-2c-8.9 0-16 7.2-16 16 0 8.7 7.8 14.6 8 14.8.5.2.7.7.7.9v10.7c0 3 2.4 5.4 5.4 5.6a5 5 0 002.2 1.4h1.4c.6-.2 1.3-.7 1.7-1.1 3 0 5.4-2.6 5.4-5.7V62.7c0-.2.5-.7.9-.9.3-.3 7.9-6.1 7.9-14.9.5-9.1-7-16.2-15.6-16.2zm-7.2 26.9s-6-4.8-6-11c0-6.3 5-11.3 11.2-11.3h2c6.3 0 11.2 5 11.2 11.2 0 6.1-5.4 10.4-6 10.9-.5.2-2.9 2-2.9 4.5v3.3h-6.9v-3.3c0-2.6-2.6-4.3-2.6-4.3zM41 70.2V73c0 .5-.5.9-.9.9H35c-.4 0-.8-.5-.8-.9v-2.8H41z" />
        <path d="M37.3 50.5L40 52l.7.2c.2 0 .6 0 .8-.2.4-.2.7-.9.7-1.3l-.7-3.2 2.4-2.1c.4-.4.4-.9.4-1.5-.2-.4-.7-.8-1.1-.8l-3.2-.4-1.5-2.8c-.2-.4-.6-.6-1.2-.6-.7 0-1.1.2-1.3.6l-1.5 2.8-3.2.4c-.4 0-1 .4-1 .8-.2.4 0 1.1.4 1.5l2.3 2.1-.7 3.2c0 .4.2 1.1.7 1.3.4.2 1 .4 1.5.2l2.8-1.7zM67.9 27.3h-7A2 2 0 0059 29l-.2 7.2c0 .5.1 1.1.5 1.4.3.4.8.6 1.2.6.5 0 1-.2 1.4-.4.4-.4.6-.8.6-1.3l.2-3 .8.9c4.2 4.2 4.2 10.9 0 15l-3.8 3.8a14.7 14.7 0 00-.9 19.5 6.3 6.3 0 001 7.3 6.2 6.2 0 008.9 0 6.2 6.2 0 000-8.8 6 6 0 00-7.2-1.1c-3.4-4.3-3-10.3.8-14l3.7-3.9A14.5 14.5 0 0066 32l-.6-.6h2.3c.5 0 1-.2 1.3-.6.3-.4.6-.8.6-1.3s-.2-1-.6-1.3c-.3-.6-.7-.8-1.1-.8zm-1.8 50c-1 1-2.6 1-3.5 0-1-.9-1-2.5 0-3.4 1-1 2.5-1 3.5 0s.9 2.5 0 3.4z" />
        <path d="M79.3 57.3c0-.4-.2-1-.5-1.3-.3-.3-.8-.5-1.3-.5a2 2 0 00-1.4.5L74 58.3l-2.3-2.2c-.3-.4-.8-.5-1.3-.5a2 2 0 00-1.4.5c-.3.3-.4.8-.4 1.3s.1 1 .6 1.3l2.3 2.2-2.3 2.1c-.3.3-.4.8-.6 1.3 0 .5.1 1 .4 1.4.4.3.8.4 1.4.4.4 0 1-.1 1.3-.4l2.5-2.3 2.2 2.3c.4.3.8.4 1.4.4.4 0 1-.1 1.3-.4.7-.8.7-2 0-2.6l-2.3-2.3 2.2-2.1c.2-.4.4-.7.4-1.4zM44.1 26c0 .5.2 1 .5 1.4.3.3.8.5 1.3.5s1-.2 1.4-.5l2.4-2.3 2.3 2.3c.3.3.8.5 1.3.5s1-.2 1.4-.5c.3-.3.4-.8.4-1.3 0-.6-.1-1-.6-1.4l-2.3-2.1 2.3-2.1c.8-.7.8-2 0-2.6-.4-.4-.8-.5-1.4-.5-.5 0-1 .1-1.3.5L49.5 20 47.3 18c-.4-.4-.8-.5-1.4-.5-.5 0-1 .1-1.3.5-.3.3-.5.8-.5 1.3s.2 1 .7 1.3l2.3 2.2-2.3 2.1c-.5.3-.7.7-.7 1.3z" />
      </g>
    </g>
  </svg>
);
