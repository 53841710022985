import React from 'react';

export const IntroCode: React.FC<React.SVGAttributes<SVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
    <path fill="#f1b70e" d="M0 0h600v600H0z" />
    <path
      d="M490 411V174a10 10 0 00-10-10H114a10 10 0 00-10 10v237a10 10 0 001 4h384a10 10 0 001-4z"
      fill="#21212d"
    />
    <circle cx="298.3" cy="172.4" r="4" fill="#fff571" />
    <circle cx="298.3" cy="172.4" r="2.8" fill="#21212d" />
    <path d="M508 430H88c-15 0-22-4-22-4h464s-7 4-22 4z" fill="#2e2d3a" />
    <rect
      x="63.9"
      y="415"
      width="469"
      height="11"
      rx="2.1"
      ry="2.1"
      fill="#393947"
    />
    <path d="M256 420h83a1 1 0 001-1v-4h-85v4a1 1 0 001 1z" fill="#2e2d3a" />
    <path fill="#f65" d="M120 181h355v223H120z" />
    <path fill="#393947" d="M132 191h331v213H132z" />
    <path fill="#21212d" d="M132 191h331v7H132z" />
    <path fill="#2e2d3a" d="M184 198h279v8H184zM184 398h279v6H184z" />
    <path fill="#2e2d3a" d="M132 198h53v206h-53zM185 284h278v9H185z" />
    <path fill="#393947" d="M132 228h53v8h-53z" />
    <path
      fill="#5f6882"
      d="M193 210h3v5h-3zM192 220h4v5h-4zM192 229h4v5h-4zM192 239h4v5h-4zM192 248h4v5h-4zM192 258h4v5h-4zM192 267h4v6h-4zM192 277h4v5h-4zM192 286h4v5h-4zM190 296h6v5h-6zM190 305h6v6h-6zM190 315h6v5h-6zM160 248h6v5h-6zM160 258h6v5h-6zM160 267h6v6h-6zM160 277h6v6h-6zM160 239h6v5h-6zM154 229h6v6h-6zM149 286h6v5h-6zM147 220h7v5h-7zM140 210h7v5h-7zM190 324h6v6h-6zM141 295h6v6h-6zM141 305h6v5h-6zM141 314h6v6h-6zM190 334h6v5h-6zM190 343h6v6h-6zM190 353h6v5h-6zM190 362h6v6h-6zM190 372h6v5h-6zM190 381h6v6h-6zM190 391h6v5h-6z"
    />
    <rect
      x="204.9"
      y="211"
      width="48"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="204.9"
      y="220"
      width="30"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="246.9"
      y="220"
      width="93"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="347.9"
      y="220"
      width="30"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="397.9"
      y="220"
      width="15"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#11f4c1"
    />
    <rect
      x="291.9"
      y="287"
      width="11"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#00c3ff"
    />
    <rect
      x="358.9"
      y="296"
      width="12"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#00c3ff"
    />
    <rect
      x="386.9"
      y="306"
      width="11"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#00c3ff"
    />
    <rect
      x="306.9"
      y="315"
      width="13"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#00c3ff"
    />
    <rect
      x="356.9"
      y="344"
      width="11"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#00c3ff"
    />
    <rect
      x="343.9"
      y="372"
      width="11"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#00c3ff"
    />
    <rect
      x="217.9"
      y="230"
      width="32"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="217.9"
      y="268"
      width="19"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="217.9"
      y="277"
      width="35"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="260.9"
      y="277"
      width="80"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="232.9"
      y="287"
      width="54"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="246.9"
      y="296"
      width="30"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="282.9"
      y="296"
      width="33"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="260.9"
      y="306"
      width="32"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#bd72ff"
    />
    <rect
      x="296.9"
      y="306"
      width="47"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="347.9"
      y="306"
      width="32"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#bd72ff"
    />
    <rect
      x="260.9"
      y="315"
      width="42"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="324.9"
      y="315"
      width="88"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="324.9"
      y="296"
      width="27"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="246.9"
      y="325"
      width="30"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="246.9"
      y="353"
      width="25"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="276.9"
      y="353"
      width="137"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="246.9"
      y="382"
      width="33"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="286.9"
      y="382"
      width="44"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="336.9"
      y="382"
      width="23"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff571"
    />
    <rect
      x="246.9"
      y="391"
      width="86"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="351.9"
      y="391"
      width="61"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="232.9"
      y="334"
      width="21"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="232.9"
      y="363"
      width="21"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="232.9"
      y="372"
      width="21"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="262.9"
      y="372"
      width="39"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="305.9"
      y="372"
      width="30"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#11f4c1"
    />
    <rect
      x="232.9"
      y="344"
      width="38"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="276.9"
      y="344"
      width="39"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="319.9"
      y="344"
      width="32"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#bd72ff"
    />
    <rect
      x="232.9"
      y="239"
      width="39"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="279.9"
      y="239"
      width="21"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="232.9"
      y="249"
      width="31"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <rect
      x="272.9"
      y="249"
      width="80"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="376.9"
      y="249"
      width="37"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#ff8989"
    />
    <rect
      x="360.9"
      y="249"
      width="13"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff571"
    />
    <rect
      x="232.9"
      y="258"
      width="83"
      height="4"
      rx="1.4"
      ry="1.4"
      fill="#fff"
    />
    <path
      fill="#5f6882"
      d="M149 211h8v2h-8zM156 222h6v2h-6zM162 232h13v2h-13zM169 240h6v2h-6zM169 250h12v2h-12zM169 260h12v2h-12zM169 270h12v2h-12zM169 279h12v3h-12zM157 288h13v2h-13zM150 297h20v2h-20zM150 307h20v2h-20zM150 317h20v2h-20z"
    />
  </svg>
);
